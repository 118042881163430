const $gallery = $(".image-gallery");
const $galleryContainer = $(".image-gallery #gallery-container");
const $gallerySlides = $(".image-gallery .gallery-wrapper .gallery-item");
const $galleryNav = $(".image-gallery .gallery-nav");
const $galleryNavEl = $(".image-gallery .gallery-nav a");
const totalSlides = $gallerySlides.length - 1;
const slideSpeed = $galleryContainer.attr("data-slide-speed");
let slideInterval = null;
let currentSlide = 0;
let intervalSlide = 0;

if($gallery.length > 0) {
    var previousScroll = window.scrollY;
    var stopScroll = true;

    // Auto scroll to gallery when scrolling from top
    $(window).scroll(function (e) {
        var currentScroll = window.scrollY;
        var $windowHeight = $gallery.offset().top - (window.innerHeight * 0.4);
        var $windowHeight2 = $gallery.offset().top - (window.innerHeight * 0.2);

        if(stopScroll) {
            if((currentScroll > $windowHeight) && (currentScroll > previousScroll) && (currentScroll < $windowHeight2)) {
                $('html, body').animate({
                    scrollTop: $gallery.offset().top
                });
                stopScroll = false;
            }
        } else if(currentScroll < $windowHeight) {
            stopScroll = true;
        }

        previousScroll = currentScroll;
    })

    function changeActiveNav(element) {
        $galleryNavEl.each(function() {
            $(this).find("span").removeClass("active");
        })
        $(element).find("span").addClass("active");
    }
    
    function translateGallery(element) {
        const $this = $(element);
    
        const $newSlide = $this.attr("data-slide");
        const $translateAmount = 100 * $newSlide;
        const $slideDiff = currentSlide - $newSlide; 
        const translateSpeed = (0.3 * Math.abs($slideDiff)) + 0.8;
  
        $galleryContainer.css({
            'transition': `transform ${translateSpeed}s cubic-bezier(0.645, 0.045, 0.355, 1)`,
            'transform': `translateY(-${$translateAmount}%)`
        });
    
        changeActiveNav($this);

        currentSlide = $newSlide;
        intervalSlide = $newSlide;
    }

    $galleryNavEl.click(function(e) {
        e.preventDefault();
        const $this = $(this);

        autoSlide(false);
        translateGallery($this);
        $('html, body').animate({
            scrollTop: $gallery.offset().top
        });

        // Start slideInterval after 5 seconds
        setTimeout(function() {
            clearInterval(slideInterval); // Clear old interval if nav buttons are clicked in fast succession
            autoSlide(true);
        }, 5000)
    })

    // Setup or destroy gallery auto slide
    function autoSlide(runInterval) { 
        if(runInterval) {
            slideInterval = setInterval(function () {
                intervalSlide++;
    
                if (intervalSlide > totalSlides){
                    intervalSlide = 0;
                }
                translateGallery($galleryNavEl.eq(intervalSlide));
            }, slideSpeed); 
        } else {
            clearInterval(slideInterval);
        }      
    }

    autoSlide(true);
}