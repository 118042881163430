const $hero = $('.hero-front-page');
let $color = 'rgba(196, 214, 0, ';
let $currBoxColor;
let $boxHeight;
let $totalHeight;
let targetOpacity = 0;
let scrollStart = 0;

if($hero !== undefined && $hero.length > 0) {
    let $boxHeightFirst = $('.hero-front-page .boxes .box').first().height().toFixed(2);
    let $boxHeightLast = $('.hero-front-page .boxes .box.show-content').find('.title-and-text').outerHeight().toFixed(2);
    let $totalHeightLast = parseInt($boxHeightFirst) + parseInt($boxHeightLast) + 24;
    $hero.find('.boxes').css({
        'height' : $totalHeightLast + ($boxHeightFirst*2) + 'px'
    });

    $hero.find('.boxes .box').click(function() {
        let $this = $(this);
        let $currOrder = $this.attr('data-order');
        targetOpacity = 1;
        scrollStart = window.scrollY;
        $boxHeight = $this.height().toFixed(2);
        let $newBoxesHeight = $this.find('.title-and-text').outerHeight().toFixed(2);
        $totalHeight = parseInt($boxHeight) + parseInt($newBoxesHeight) + 24;
        $currBoxColor = $this.data('color');
        if($currBoxColor == 'green') {
            $color = 'rgba(196, 214, 0, ';
        }
        else if($currBoxColor == 'blue') {
            $color = 'rgba(0, 40, 86, ';
        }
        else {
            $color = 'rgba(27, 172, 228, ';
        }

        if(!$this.hasClass('show-content')) {
            $hero.find('.boxes').css({
                'height' : $totalHeight + ($boxHeight*2) + 'px'
            });
            $hero.find('.boxes .box').removeClass('show-content');
            $this.addClass('show-content');

            $hero.find('.boxes .box').each(function(){
                
                if(!$(this).hasClass('show-content')) {
                    if($(this).data('color') == 'blue') {
                        $(this).find('.box-color').css({
                            'background-color' : 'rgba(0, 40, 86, 1)',
                            'color' : 'rgba(255, 255, 255, 1)'
                        });
                    }
                    else if($(this).data('color') == 'green') {
                        $(this).find('.box-color').css({
                            'background-color' : 'rgba(196, 214, 0, 1)'
                        });
                    }
                    else {
                        $(this).find('.box-color').css({
                            'background-color' : 'rgba(27, 172, 228, 1)'
                        });
                    }
                }
            });

            let $firstBox = $('.box[data-order="1"]');
            let $secondBox = $('.box[data-order="2"]');
            let $thirdBox = $('.box[data-order="3"]');

            if($currOrder == 1) {
                $secondBox.css({
                    'transform' : 'translateY(0)',
                    'z-index' : 1
                });
                $thirdBox.css({
                    'transform' : 'translateY(' + ($boxHeight) + 'px)',
                    'z-index' : 2
                });
                $thirdBox.attr('data-order', 2);
                $secondBox.attr('data-order', 1);
            }
            else if($currOrder == 2) {
                $firstBox.css({
                    'transform' : 'translateY(' + ($boxHeight) + 'px)',
                    'z-index' : 2
                });
                $thirdBox.css({
                    'transform' : 'translateY(0)',
                    'z-index' : 1
                });
                $firstBox.attr('data-order', 2);
                $thirdBox.attr('data-order', 1);
            }
            $this.css({
                'transform' : 'translateY(' + (($boxHeight*2) - 1)  + 'px)',
                'z-index': 3
            });
            $this.attr('data-order', 3);

            $('.hero-front-page .boxes .box').each((i, e)=> {
                e.classList.add("hover-bump")
            })

            if(window.scrollY >= 5) {
                $this.removeClass("hover-bump");
            }
        }
    });

    $(function () {
        $(window).scroll(function () {
            backgroundTransition();

            if(window.scrollY < 5) {
                $('.hero-front-page .boxes .box').each((i, e)=> {
                    e.classList.add("hover-bump")
                })
                document.querySelector('.hero-front-page .boxes .box.show-content').classList.add("hover-bump");
            } else {
                document.querySelector('.hero-front-page .boxes .box.show-content').classList.remove("hover-bump");
            }
        });
    });
}
let textOpacity = 0;

function backgroundTransition() {
    let scrollPos = $(document).scrollTop();
    let currBoxHeight = $hero.find('.boxes .box.show-content').height();
    let textColorOpacity = 0;
    if (scrollPos > 100) {
        if(scrollStart <= 200) {
            targetOpacity = 1 - (((scrollPos - scrollStart)/currBoxHeight)*1.6);
            textColorOpacity = 1 - (((scrollPos - scrollStart)/currBoxHeight)*2);
        }
        else {
            targetOpacity = 1 - (((scrollPos - scrollStart)/currBoxHeight)*8);
            textColorOpacity = 1 - (((scrollPos - scrollStart)/currBoxHeight)*9);
        }
        $('.box.show-content .box-color').css({
            'background-color' : $color + targetOpacity + ')'
        });
        if($currBoxColor == 'blue') {
            $('.box.show-content .box-color').css({
                'color' : 'rgba(255, 255, 255, ' + textColorOpacity + ')'
            });
            $('.box.show-content .box-color a').css({
                'color' : 'rgba(255, 255, 255, ' + textColorOpacity + ')'
            });
        }
        if(textColorOpacity <= 0.4) {
            textOpacity = 0.45 - (textColorOpacity);
            if($currBoxColor == 'blue') {
                $('.box.show-content .box-color').css({
                    'color' : 'rgba(0, 40, 86, ' + textOpacity + ')'
                });
                $('.box.show-content .box-color a').css({
                    'color' : 'rgba(0, 40, 86, ' + textOpacity + ')'
                });
            }
        }
    } else {
        $('.box.show-content .box-color').css({
            'background-color' : $color + 1 + ')'
        });
    }
};