const $referenceTabs = $(".references-list .reference-tab");
const $referenceListImageDesktop = $(".references-list .image-desktop img");

// Open and close tabs
if($referenceTabs.length > 0) {
    var previousScroll = window.scrollY;
    var stopScroll = true;

    $(window).scroll(function (e) {
        var currentScroll = window.scrollY;
        var $windowHeight = $(".references-list").offset().top - (window.innerHeight * 0.4);
        var $windowHeight2 = $(".references-list").offset().top - (window.innerHeight * 0.2);

        if(stopScroll) {
            if((currentScroll > $windowHeight) && (currentScroll > previousScroll) && (currentScroll < $windowHeight2)) {
                $('html, body').animate({
                    scrollTop: $(".references-list").offset().top
                });
                stopScroll = false;
            }
        } else if(currentScroll < $windowHeight) {
            stopScroll = true;
        }

        previousScroll = currentScroll;
    })

    if(window.innerWidth <= 1200) {
        $(".references-list .references-wrapper").each((i, e) => {
            $(e).removeClass("open");
            $(e).parent().find(".tab-container svg").removeClass("open");
            $(e).parents(".list-item").removeClass("background-color");
            $(e).hide();
            
            $(".references-list .reference-tab.first-item").parent().siblings().eq(0).addClass("open");
            $(".references-list .reference-tab.first-item").parents(".list-item").addClass("background-color");
            $(".references-list .reference-tab.first-item").parent().siblings().eq(0).slideDown();
            $(".references-list .reference-tab.first-item").next().addClass("open");
        })
    }

    let previousSize;

    $(window).resize(function(){ 
        if(window.innerWidth > 1200) {
            $(".references-list .references-wrapper.open-desktop").each((i, e) => {
                $(e).show();
            })
            
            $(".references-list .references-wrapper.open").slideUp();
            $(".references-list .references-wrapper.open").removeClass("open");
            $(".references-list .list-item.background-color svg.open").removeClass("open");
            $(".references-list .list-item.background-color").removeClass("background-color");

            $(".references-list .reference-tab.first-item").parent().siblings().eq(0).removeClass("open");
            $(".references-list .reference-tab.first-item").parents(".list-item").removeClass("background-color");
            $(".references-list .reference-tab.first-item").parent().siblings().eq(0).slideUp();
            $(".references-list .reference-tab.first-item").next().removeClass("open");
        } else {
            $(".references-list .references-wrapper").each((i, e) => {
                if((!($(e).parent().children().eq(0).children().eq(0).hasClass("first-item"))) && (previousSize > 1200)) {
                    $(e).removeClass("open");
                    $(e).parent().find(".tab-container svg").removeClass("open");
                    $(e).parents(".list-item").removeClass("background-color");
                    $(e).hide();
                }
            });

            if((window.innerWidth < 1200) && (previousSize > 1200 - 10)) {
                $(".references-list .reference-tab.first-item").parent().siblings().eq(0).addClass("open");
                $(".references-list .reference-tab.first-item").parents(".list-item").addClass("background-color");
                $(".references-list .reference-tab.first-item").parent().siblings().eq(0).slideDown();
                $(".references-list .reference-tab.first-item").next().addClass("open");
            }
        }

        previousSize = window.innerWidth;
    })

    $referenceTabs.click((e) => {
        e.preventDefault();
        const $this = $(e.target);

        if(window.innerWidth < 1200) {
            $(".references-list .references-wrapper.open").each((i, e) => {
                if($this.data("reference") !== $(e).data("reference")) {
                    $(e).removeClass("open");
                    $(e).parent().find(".tab-container svg").removeClass("open");
                    $(e).parents(".list-item").removeClass("background-color");
                    $(e).slideUp();
                }
            })

            if($this.parent().siblings().eq(0).hasClass("open")) {
                $this.parent().siblings().eq(0).removeClass("open");
                $this.parents(".list-item").removeClass("background-color");
                $this.parent().siblings().eq(0).slideUp();
                $this.next().removeClass("open");
            } else {
                $this.parent().siblings().eq(0).addClass("open");
                $this.parents(".list-item").addClass("background-color");
                $this.parent().siblings().eq(0).slideDown();
                $this.next().addClass("open");
            }
        } else {
            const referenceImage = $this.data("src");
            $referenceListImageDesktop.attr("src", referenceImage);

            $(".references-list .references-wrapper.open-desktop").each((i, e) => {
                if($this.data("reference") !== $(e).data("reference")) {
                    $(e).removeClass("open-desktop");
                    $(e).parent().find(".tab-container svg").removeClass("open-desktop");
                    $(e).parents(".list-item").removeClass("background-color-desktop");
                    $(e).slideUp();
                    $(e).parent().find(".tab-container").removeClass("open-desktop");
                }
            })

            if(!$this.parent().siblings().eq(0).hasClass("open-desktop")) {
                $this.parent().siblings().eq(0).addClass("open-desktop");
                $this.parents(".list-item").addClass("background-color-desktop");
                $this.parent().siblings().eq(0).slideDown();
                $this.parent().addClass("open-desktop");
            }
        }

        $('html, body').animate({
            scrollTop: $(".references-list").offset().top
        });
    })
}