// left: 37, up: 38, right: 39, down: 40,
// spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
var keys = {37: 1, 38: 1, 39: 1, 40: 1};

function preventDefault(e) {
  e.preventDefault();
}

function preventDefaultForScrollKeys(e) {
  if (keys[e.keyCode]) {
    preventDefault(e);
    return false;
  }
}

// modern Chrome requires { passive: false } when adding event
var supportsPassive = false;
try {
  window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
    get: function () { supportsPassive = true; } 
  }));
} catch(e) {}

var wheelOpt = supportsPassive ? { passive: false } : false;
var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

// call this to Disable
function disableScroll() {
  window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
  window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
  window.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
  window.addEventListener('keydown', preventDefaultForScrollKeys, false);
}

// call this to Enable
function enableScroll() {
  window.removeEventListener('DOMMouseScroll', preventDefault, false);
  window.removeEventListener(wheelEvent, preventDefault, wheelOpt); 
  window.removeEventListener('touchmove', preventDefault, wheelOpt);
  window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
}

/*
 * jQuery Easing v1.4.1 - http://gsgd.co.uk/sandbox/jquery/easing/
 * Open source under the BSD License.
 * Copyright © 2008 George McGinley Smith
 * All rights reserved.
 * https://raw.github.com/gdsmith/jquery.easing/master/LICENSE
*/

/* globals jQuery, define, module, require */
(function (factory) {
	if (typeof define === "function" && define.amd) {
		define(['jquery'], function ($) {
			return factory($);
		});
	} else if (typeof module === "object" && typeof module.exports === "object") {
		module.exports = factory(require('jquery'));
	} else {
		factory(jQuery);
	}
})(function($){

	// Preserve the original jQuery "swing" easing as "jswing"
	if (typeof $.easing !== 'undefined') {
		$.easing['jswing'] = $.easing['swing'];
	}

	var pow = Math.pow,
		sqrt = Math.sqrt,
		sin = Math.sin,
		cos = Math.cos,
		PI = Math.PI,
		c1 = 1.70158,
		c2 = c1 * 1.525,
		c3 = c1 + 1,
		c4 = ( 2 * PI ) / 3,
		c5 = ( 2 * PI ) / 4.5;

	// x is the fraction of animation progress, in the range 0..1
	function bounceOut(x) {
		var n1 = 7.5625,
			d1 = 2.75;
		if ( x < 1/d1 ) {
			return n1*x*x;
		} else if ( x < 2/d1 ) {
			return n1*(x-=(1.5/d1))*x + .75;
		} else if ( x < 2.5/d1 ) {
			return n1*(x-=(2.25/d1))*x + .9375;
		} else {
			return n1*(x-=(2.625/d1))*x + .984375;
		}
	}

	$.extend( $.easing, {
		def: 'easeOutQuad',
		swing: function (x) {
			return $.easing[$.easing.def](x);
		},
		easeInQuad: function (x) {
			return x * x;
		},
		easeOutQuad: function (x) {
			return 1 - ( 1 - x ) * ( 1 - x );
		},
		easeInOutQuad: function (x) {
			return x < 0.5 ?
				2 * x * x :
				1 - pow( -2 * x + 2, 2 ) / 2;
		},
		easeInCubic: function (x) {
			return x * x * x;
		},
		easeOutCubic: function (x) {
			return 1 - pow( 1 - x, 3 );
		},
		easeInOutCubic: function (x) {
			return x < 0.5 ?
				4 * x * x * x :
				1 - pow( -2 * x + 2, 3 ) / 2;
		},
		easeInQuart: function (x) {
			return x * x * x * x;
		},
		easeOutQuart: function (x) {
			return 1 - pow( 1 - x, 4 );
		},
		easeInOutQuart: function (x) {
			return x < 0.5 ?
				8 * x * x * x * x :
				1 - pow( -2 * x + 2, 4 ) / 2;
		},
		easeInQuint: function (x) {
			return x * x * x * x * x;
		},
		easeOutQuint: function (x) {
			return 1 - pow( 1 - x, 5 );
		},
		easeInOutQuint: function (x) {
			return x < 0.5 ?
				16 * x * x * x * x * x :
				1 - pow( -2 * x + 2, 5 ) / 2;
		},
		easeInSine: function (x) {
			return 1 - cos( x * PI/2 );
		},
		easeOutSine: function (x) {
			return sin( x * PI/2 );
		},
		easeInOutSine: function (x) {
			return -( cos( PI * x ) - 1 ) / 2;
		},
		easeInExpo: function (x) {
			return x === 0 ? 0 : pow( 2, 10 * x - 10 );
		},
		easeOutExpo: function (x) {
			return x === 1 ? 1 : 1 - pow( 2, -10 * x );
		},
		easeInOutExpo: function (x) {
			return x === 0 ? 0 : x === 1 ? 1 : x < 0.5 ?
				pow( 2, 20 * x - 10 ) / 2 :
				( 2 - pow( 2, -20 * x + 10 ) ) / 2;
		},
		easeInCirc: function (x) {
			return 1 - sqrt( 1 - pow( x, 2 ) );
		},
		easeOutCirc: function (x) {
			return sqrt( 1 - pow( x - 1, 2 ) );
		},
		easeInOutCirc: function (x) {
			return x < 0.5 ?
				( 1 - sqrt( 1 - pow( 2 * x, 2 ) ) ) / 2 :
				( sqrt( 1 - pow( -2 * x + 2, 2 ) ) + 1 ) / 2;
		},
		easeInElastic: function (x) {
			return x === 0 ? 0 : x === 1 ? 1 :
				-pow( 2, 10 * x - 10 ) * sin( ( x * 10 - 10.75 ) * c4 );
		},
		easeOutElastic: function (x) {
			return x === 0 ? 0 : x === 1 ? 1 :
				pow( 2, -10 * x ) * sin( ( x * 10 - 0.75 ) * c4 ) + 1;
		},
		easeInOutElastic: function (x) {
			return x === 0 ? 0 : x === 1 ? 1 : x < 0.5 ?
				-( pow( 2, 20 * x - 10 ) * sin( ( 20 * x - 11.125 ) * c5 )) / 2 :
				pow( 2, -20 * x + 10 ) * sin( ( 20 * x - 11.125 ) * c5 ) / 2 + 1;
		},
		easeInBack: function (x) {
			return c3 * x * x * x - c1 * x * x;
		},
		easeOutBack: function (x) {
			return 1 + c3 * pow( x - 1, 3 ) + c1 * pow( x - 1, 2 );
		},
		easeInOutBack: function (x) {
			return x < 0.5 ?
				( pow( 2 * x, 2 ) * ( ( c2 + 1 ) * 2 * x - c2 ) ) / 2 :
				( pow( 2 * x - 2, 2 ) *( ( c2 + 1 ) * ( x * 2 - 2 ) + c2 ) + 2 ) / 2;
		},
		easeInBounce: function (x) {
			return 1 - bounceOut( 1 - x );
		},
		easeOutBounce: bounceOut,
		easeInOutBounce: function (x) {
			return x < 0.5 ?
				( 1 - bounceOut( 1 - 2 * x ) ) / 2 :
				( 1 + bounceOut( 2 * x - 1 ) ) / 2;
		}
	});
	return $;
});

/*
 *
 * TERMS OF USE - EASING EQUATIONS
 * 
 * Open source under the BSD License. 
 * 
 * Copyright Â© 2001 Robert Penner
 * All rights reserved.
 * 
 * Redistribution and use in source and binary forms, with or without modification, 
 * are permitted provided that the following conditions are met:
 * 
 * Redistributions of source code must retain the above copyright notice, this list of 
 * conditions and the following disclaimer.
 * Redistributions in binary form must reproduce the above copyright notice, this list 
 * of conditions and the following disclaimer in the documentation and/or other materials 
 * provided with the distribution.
 * 
 * Neither the name of the author nor the names of contributors may be used to endorse 
 * or promote products derived from this software without specific prior written permission.
 * 
 * THIS SOFTWARE IS PROVIDED BY THE COPYRIGHT HOLDERS AND CONTRIBUTORS "AS IS" AND ANY 
 * EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF
 * MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL THE
 *  COPYRIGHT OWNER OR CONTRIBUTORS BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL,
 *  EXEMPLARY, OR CONSEQUENTIAL DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
 *  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS INTERRUPTION) HOWEVER CAUSED 
 * AND ON ANY THEORY OF LIABILITY, WHETHER IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING
 *  NEGLIGENCE OR OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THIS SOFTWARE, EVEN IF ADVISED 
 * OF THE POSSIBILITY OF SUCH DAMAGE. 
 *
 */

var $slideshowItems = $('.slideshow-item');
var scrollable = true;

if ($slideshowItems.length > 1) {
    var lastScroll = window.scrollY;
    var currentSlideNum = 1;

    $(window).scroll(function (e) {
            var scrollTop = $(e.target).scrollTop();
            var firstTop = $($slideshowItems[0]).offset().top;
            var lastTop = $($slideshowItems[$slideshowItems.length - 1]).offset().top;
            var $slideshowLength = $slideshowItems.length;
            var $slideNavItems = $(".slide-nav ul li a");
            var $slideCounter = $(".slide-counter .current-slide");

            $slideshowItems.each(function(index, el) {
                var $slideshowItem = $(el);
                var offsetTop = $slideshowItem.offset().top;
                var height = $slideshowItem.outerHeight();
                var reducedHeight = height*0.5;
                var heightTop = height * index;
                var heightBottom = height * (index + 1);
                var $figure = $slideshowItem.find(".slide");
                //var $overlay = $slideshowItem.find('dark-overlay');

                if((scrollTop > firstTop && scrollTop <= lastTop)) {
                    if((scrollTop > offsetTop) && (scrollTop < (firstTop+heightBottom))) {
                        if(scrollTop > lastScroll) {
                            if(index == 3) {
                                currentSlideNum = (index + 1);
                            } else {
                                currentSlideNum = (index + 1);
                            }
                        } else {
                            currentSlideNum = (index + 1);
                        }
                    }
                }

                if (scrollTop > firstTop && scrollTop <= lastTop) {
                    var diff = scrollTop + height - offsetTop;
                    var index1 = index + 1;
                    
                    
                    if((scrollTop > (firstTop+heightTop-reducedHeight)) && (scrollTop < (firstTop+heightBottom))) {
                        $slideCounter.html("0" + index1);
                        $slideNavItems.each((navIndex, item) => {
                            $(item).children().eq(0).removeClass("active")
                        });
                        $slideNavItems.eq(index).children().eq(0).addClass("active");
                    } else if((scrollTop > firstTop+heightTop)) {
                        $slideCounter.html("0" + $slideshowLength);
                    }

                    //$figure.addClass('fixed');
                    /* if (index > 0) {
                        $figure.css('height', '' + Math.min(diff, height) + 'px');
                    } */

/*                     if (index > 0) {
                        var $prevOverlay = $($slideshowItems[index - 1]).find('.dark-overlay');
                        $prevOverlay.css('opacity', Math.min(diff / height / 2, 0.5));
                    } */

                } /* else {
                    $figure.removeClass('fixed');
                    $figure.css('height', '');
                    $overlay.css('opacity', 0);
                } */
                
                if((scrollTop > firstTop) && (scrollTop < lastTop)) {
                    $(".scrollSkip svg").addClass("fixed");
                    $(".slide-nav").addClass("fixed");
                    $(".slide-counter").addClass("fixed");
                    $(".lastSkip").css("display", "none");
                    $(".slide-nav").removeClass("lastSlide");
                    $(".slide-counter").removeClass("lastSlide");
                } else {
                    $(".scrollSkip svg").removeClass("fixed");
                    $(".slide-nav").removeClass("fixed");
                    $(".slide-counter").removeClass("fixed");
                    $(".lastSkip").css("display", "block");
                    
                    if(scrollTop > lastTop) {
                        $(".slide-nav").addClass("lastSlide");
                        $(".slide-counter").addClass("lastSlide");
                    }
                }
            });

            if(window.innerWidth > 600) {
                var firstTopScrollIn = firstTop - 400;
                if((currentSlideNum === 1) && (scrollTop > firstTopScrollIn) && (scrollTop < firstTop) && (scrollTop > lastScroll)) {
                    var $target = $(".slide1");

                    if(scrollable) {
                        scrollable = false;
                        disableScroll();
                        $('html, body').animate({ scrollTop: $target.offset().top }, 2000, "easeInOutQuint", function() {
                            scrollable = true;
                            enableScroll();
                        });
                    }         
                } else {
                    if((scrollTop > lastScroll) && (scrollTop > firstTop && scrollTop <= lastTop)) {
                        var $target = $(".slide" + (currentSlideNum + 1));
                        if(scrollable) {
                            scrollable = false;   
                            disableScroll();
                            $('html, body').animate({ scrollTop: $target.offset().top }, 2000, "easeInOutQuint", function() {
                                scrollable = true;
                                enableScroll();
                            });
                        }         
                    }/*  else if((scrollTop < lastScroll) && (scrollTop > firstTop && scrollTop <= lastTop)) {
                        currentSlideNum = currentSlideNum + 1;
                        var $target = $(".slide" + (currentSlideNum - 1));
                        if(scrollable) {
                            scrollable = false;

                            $('html, body').animate({ scrollTop: $target.offset().top }, 1500, "easeInOutQuint", function() {
                                setTimeout(() => {
                                    scrollable = true;
                                },300)
                            });
                        }
                    } */
                }
            }

            lastScroll = scrollTop;
        
    });

    $(document).ready(function (e) {
        var scrollTop = $(window).scrollTop();
        var firstTop = $($slideshowItems[0]).offset().top;
        var lastTop = $($slideshowItems[$slideshowItems.length - 1]).offset().top;
        var $slideshowLength = $slideshowItems.length;
        var $slideNavItems = $(".slide-nav ul li a");
        var $slideCounter = $(".slide-counter .current-slide");
        
        $slideshowItems.each(function(index, el) {
            var $slideshowItem = $(el);
            var height = $slideshowItem.outerHeight();
            var reducedHeight = height*0.5;
            var heightTop = height * index;
            var heightBottom = height * (index + 1);
            var index1 = index + 1;
            
            if((scrollTop > (firstTop+heightTop-reducedHeight)) && (scrollTop < (firstTop+heightBottom))) {
                $slideCounter.html("0" + index1);
                $slideNavItems.each((navIndex, item) => {
                    $(item).children().eq(0).removeClass("active")
                });

                $slideNavItems.eq(index).children().eq(0).addClass("active");
            } else if((scrollTop > firstTop+heightTop)) {
                $slideCounter.html("0" + $slideshowLength);
            }
        });

        if((scrollTop > lastScroll) && (scrollTop > firstTop && scrollTop <= lastTop)) {
            var $target = $(".slide" + (currentSlideNum + 1));

            if(scrollable) {
                scrollable = false;
                $('html, body').animate({ scrollTop: $target.offset().top }, 1500, "easeInOutQuint", function() {
                    setTimeout(() => {
                        scrollable = true;
                    },300)
                });   
            }     
        } else if((scrollTop < lastScroll) && (scrollTop > firstTop && scrollTop <= lastTop)) {
            currentSlideNum = currentSlideNum + 1;
            var $target = $(".slide" + (currentSlideNum - 1));

            if(scrollable) {
                scrollable = false;
                $('html, body').animate({ scrollTop: $target.offset().top }, 1500, "easeInOutQuint", function() {
                    setTimeout(() => {
                        scrollable = true;
                    },300)
                });
            }
        }

        lastScroll = scrollTop;
    
});
}

$(".slideshow .slideNavItem").click((e) => {
    e.preventDefault();
    var $this = $(e.target);
    var $target = $("#slide" + $this.attr("data-index"));
    if(scrollable) {
        scrollable = false
        disableScroll();
        $('html, body').animate({ scrollTop: $target.offset().top }, 2000, "easeInOutQuint", function() {
            scrollable = true;
            enableScroll();
        });
    }
})
$(".slideshow .scrollSkip").click((e) => {
    e.preventDefault();
    const $this = $(e.target);
    var $target = $this.parents(".slideshow").next('*');
    if(scrollable) {
        scrollable = false
        disableScroll();
        $('html, body').animate({ scrollTop: $target.offset().top }, 2000, "easeInOutQuint", function() {
            scrollable = true;
            enableScroll();
        });
    }
})
$(".slideshow .lastSkip").click((e) => {
    e.preventDefault();
    const $this = $(e.target);
    var $target = $this.parents(".slideshow").next('*');
    if(scrollable) {
        scrollable = false;
        disableScroll();
        $('html, body').animate({ scrollTop: $target.offset().top }, 2000, "easeInOutQuint", function() {
            scrollable = true;
            enableScroll();
        });
    }
})