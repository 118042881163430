let $news;
let $newsPost;
let $selectedFilters = ['null', 'null'];
let $loadCount;

if($('.tease-news_no').length > 0) {
    $news = $('.tease-news_no');
}
else if ($('.tease-news_en').length > 0) {
    $news = $('.tease-news_en');
}

if($('.post-type-news_no').length > 0) {
    $newsPost = $('.post-type-news_no');
}
else if ($('.post-type-news_en').length > 0) {
    $newsPost = $('.post-type-news_en');
}

if($newsPost !== undefined) {
    if($newsPost.length > 0) {
        let postColor = $newsPost.find('.hero-thumbnail .image').data('post-color');
        if(postColor == 'dark-blue') {
            $('header').addClass('white-menu');
        }
    }
}

if($('.news-archive') !== undefined) {
    if($('.news-archive').length > 0) {
        if($(window).width() < 900) {
            $loadCount = 6;
        }
        else {
            $loadCount = 8;
        }
        if($(window).width() < 600) {
            $('article.tease.show').eq(length-1).addClass('no-border');
        }
        $('.news-archive .categories').find('a').click(function(e) {
            e.preventDefault();
            if(!$(this).hasClass('active')) {
                $('.news-archive .categories').find('a').removeClass('active');
                $(this).addClass('active');
                if($(this).data('slug') != 'all') {
                    $selectedFilters[0] = ($(this).data('slug'));
                }
                else {
                    $selectedFilters[0] = ('null');
                }
                filterArticles();
            }
        });
        $('.news-archive .years').find('a').click(function(e) {
            e.preventDefault();
            if($('.news-archive .years a.active').length == $('.news-archive .years a').length) {
                $('.news-archive .years').find('a').removeClass('active');
                $(this).addClass('active');
                $selectedFilters[1] = ($(this).data('slug'));
            }
            else if(!$(this).hasClass('active')) {
                $('.news-archive .years').find('a').removeClass('active');
                $(this).addClass('active');
                $selectedFilters[1] = ($(this).data('slug'));
            }
            else {
                $('.news-archive .years').find('a').addClass('active');
                $selectedFilters[1] = ('null');
            }
            filterArticles();
        });

        $('.news-archive').find('.load-more').click(function(e) {
            e.preventDefault();
            let $postsNotShown = $('article.tease:not(.show)');
            if($('article.tease.show').length < $('article.tease').length) {
                for(let i=0; i < $postsNotShown.length; i++) {
                    if(i < $loadCount) {
                        $postsNotShown.eq(i).show();
                        $postsNotShown.eq(i).addClass('show');
                    }
                };
            }
            if($(window).width() < 600) {
                $('article.tease').removeClass('no-border');
                $('article.tease.show').eq(length-1).addClass('no-border');
            }
            if($('article.tease.show').length >= $('article.tease').length) {
                $(this).parent().addClass('hide');
            }
        });
    }
}

function filterArticles() {
    let $articlesClasses = 'null';
    if(($selectedFilters[0] != 'null' || $selectedFilters[1] != 'null')) {
        if($selectedFilters[0] == 'null') {
            $articlesClasses = $selectedFilters[1];
        }
        else if($selectedFilters[1] == 'null') {
            $articlesClasses = $selectedFilters[0];
        }
        else {
            for (let i = 0; i<$selectedFilters.length; i++) {
                $articlesClasses = $selectedFilters.join('.');
            }
        }
    }
    if ($articlesClasses != 'null') {
            $('article.tease').removeClass('show');
            $('article.tease').hide();
            $('article.tease.' + $articlesClasses).addClass('show');
            $('article.tease.' + $articlesClasses).show();
            $('.news-archive').find('.more-button').addClass('hide');
    }
    else {
        $('article.tease').show();
        $('article.tease').addClass('show');
        $('.news-archive').find('.more-button').removeClass('hide');
    }
    let $articlesShown = $('article.tease.show');
    $('article.tease').removeClass('show');
    $('article.tease').hide();
    $articlesShown.each(function(index) {
        if(index < 12) {
            $(this).addClass('show');
            $(this).show();
        }
    });
    if($('article.tease.show').length >= $('article.tease').length) {
        $('.news-archive').find('.more-button').addClass('hide');
    }
}