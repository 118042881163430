const $referenceTabs = $(".references-archive .archive .tease-reference .reference-tab");

// Open and close tabs
if($referenceTabs.length > 0) {
    if(window.innerWidth <= 1200) {
        $(".references-archive .reference-tab.first-item").parent().siblings().eq(0).addClass("open");
        $(".references-archive .reference-tab.first-item").parents(".tease-reference").addClass("background-color");
        $(".references-archive .reference-tab.first-item").parent().siblings().eq(0).slideDown();
        $(".references-archive .reference-tab.first-item").next().addClass("open");
    }
    console.log()

    let previousSize;

    $(window).resize(function(){ 
        if(window.innerWidth > 900) {
            $(".references-archive .archive .tease-reference .references-wrapper").each((i, e) => {
                $(e).show();
            })

            $(".references-archive .reference-tab.first-item").parent().siblings().eq(0).removeClass("open");
            $(".references-archive .reference-tab.first-item").parents(".tease-reference").removeClass("background-color");
            $(".references-archive .reference-tab.first-item").next().removeClass("open");
        } else {
            $(".references-archive .archive .tease-reference .references-wrapper").each((i, e) => {
                if((!($(e).parent().children().eq(0).children().eq(0).hasClass("first-item"))) && (previousSize > 900)) {
                    $(e).removeClass("open");
                    $(e).parent().find(".tab-container svg").removeClass("open");
                    $(e).parents(".tease-reference").removeClass("background-color");
                    $(e).hide();
                }
            })

            if((window.innerWidth < 900) && (previousSize > 900)) {
                $(".references-archive .reference-tab.first-item").parent().siblings().eq(0).addClass("open");
                $(".references-archive .reference-tab.first-item").parents(".tease-reference").addClass("background-color");
                $(".references-archive .reference-tab.first-item").parent().siblings().eq(0).slideDown();
                $(".references-archive .reference-tab.first-item").next().addClass("open");
            }
        }

        previousSize = window.innerWidth;
    })

    $referenceTabs.click((e) => {
        e.preventDefault();
        const $this = $(e.target);

        $(".references-archive .archive .tease-reference .references-wrapper.open").each((i, e) => {
            if($this.data("reference") !== $(e).data("reference")) {
                $(e).removeClass("open");
                $(e).parent().find(".tab-container svg").removeClass("open");
                $(e).parents(".tease-reference").removeClass("background-color");
                $(e).slideUp();
            }
        })

        if($this.parent().siblings().eq(0).hasClass("open")) {
            $this.parent().siblings().eq(0).removeClass("open");
            $this.parents(".tease-reference").removeClass("background-color");
            $this.parent().siblings().eq(0).slideUp();
            $this.next().removeClass("open");
        } else {
            $this.parent().siblings().eq(0).addClass("open");
            $this.parents(".tease-reference").addClass("background-color");
            $this.parent().siblings().eq(0).slideDown();
            $this.next().addClass("open");
        }
    })
}