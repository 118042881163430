$('.title-and-text .continue-indicator a').click(function (e) {
    if ($(this).attr('href') === '#') {
        e.preventDefault();

        var $this = $(this);
        var $parent = $this.parents('.title-and-text');
        var $next = $parent.next('*');

        if ($next.length > 0) {
            $('html, body').animate({
                scrollTop: $next.offset().top
            }, 400);
        }
    }
});